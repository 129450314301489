<template>
  <footer>
    <div class="footer_wrapper_outer">
      <div class="footer_wrapper flx">
        <div class="left_box flx">
          <div class="column account_footer flx" v-if="user">
            <span class="footer_title">ЛИЧНЫЙ КАБИНЕТ</span>
            <a href="/profile">Личный кабинет</a>
            <a href="/orders">История заказов</a>
            <a href="/favorites">Мои закладки</a>
            <a href="/payments">Выплаты</a>
          </div>
          <div class="column account_footer flx" v-else>
            <span class="footer_title">ЛИЧНЫЙ КАБИНЕТ</span>
            <a href="/login">Личный кабинет</a>
            <a href="/login">История заказов</a>
            <a href="/login">Мои закладки</a>
            <a href="/login">Выплаты</a>
          </div>
          <div class="column flx" v-if="contents_info.length > 0">
            <span class="footer_title">Информация</span>
            <a
              v-for="content in contents_info"
              :key="content.id"
              :href="'/content/'+content.url">{{content.title}}
            </a>
          </div>
          <div class="column flx" v-if="contents_support.length > 0">
            <span class="footer_title">СЛУЖБА ПОДДЕРЖКИ</span>
            <a
              v-for="content in contents_support"
              :key="content.id"
              :href="'/content/'+content.url">{{content.title}}
            </a>
          </div>
        </div>
        <div class="right_box flx">
          <social-links></social-links>
          <div class="payment_system_ico flx">
            <img src="/images/visa.png" alt="visa">
            <img src="/images/mastercard.png" alt="mastercard">
          </div>
          <ul class="all_rights_box flx">
            <li>
              <span class="logo_footer"></span>
              <span>BrewDrop</span>
            </li>
            <li>© 2021 Brewdrop.com</li>
            <li>All rights reserved</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SocialLinks from '../../components/modules/SocialLinks/SocialLinks'

export default {
  name: 'FooterBlock',
  components: { SocialLinks },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('contents', ['contents_support', 'contents_info'])
  },
  methods: {
    ...mapActions('contents', ['getContentsInfo', 'getContentSupport'])
  },
  created () {
    this.getContentsInfo()
    this.getContentSupport()
  }
}
</script>

<style scoped>

</style>
