export const pageClassPlugin = {
  install (Vue, options) {
    Vue.mixin({
      watch: {
        $route: {
          immediate: true,
          handler (val) {
            const pageClass = getPageClass(this)
            if (pageClass) {
              document.body.className = pageClass
            }
          }
        }
      },
      methods: {
        setPaginationPage (page) {
          if (page !== parseInt(this.$route.query.page, 10) && page !== 1) {
            this.$router.push({ query: { ...this.$route.query, page: page } })
          } else if (this.$route.query.page && page === 1) {
            this.$router.push({ query: { ...this.$route.query, page: undefined } })
          }
        }
      }
    })
  }
}
function getPageClass (vm) {
  const { pageClass } = vm.$options
  if (pageClass) {
    return typeof pageClass === 'function'
      ? pageClass.call(vm)
      : pageClass
  }
}
