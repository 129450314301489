<template>
  <nav>
    <div @click="hover=false" v-show="hover" class="close-layout"></div>
    <ul :class="ulClass">
      <li
          class="catalog-drop-block"
      >
        <a class="btn btn-catalog" @click="hover = !hover">
          <img src="/images/burger.svg" alt="">
          <span>Каталог</span>
        </a>
        <ul v-show="hover" class="catalog-drop">
          <li v-for="(item, index) in items" :key="item.routeName + '-full-' + index">
              <router-link v-if="item.id" :to="{ name: 'Catalog', query: { filters: [], categories: `${item.id}` } }" exact>{{item.name}}</router-link>
              <router-link v-else :to="{ name: 'Catalog', query: { filters: [] } }" exact>{{item.name}}</router-link>
          </li>
        </ul>
      </li>
      <li>
        <router-link :to="{ name: 'Catalog', query: { filters: [] } }" exact> Все категории</router-link>
      </li>
<!--      <li v-for="(item, index) in itemsLimited" :key="item.routeName + '-' + index">-->
<!--        <slot :item="item">{{ item.title }}</slot>-->
<!--      </li>-->
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'NavMenu',
  data () {
    return {
      hover: false,
      itemsLimited: []
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    ulClass: {
      type: String,
      default: ''
    }
  },
  watch: {
    items: {
      immediate: true,
      handler (val) {
        this.itemsLimited = val.slice(0, 5)
      }
    },
    $route: {
      immediate: true,
      handler (val, old) {
        console.log('set route sss')
        this.hover = false
      }
    }
  }
}
</script>

<style scoped>

</style>
