import axios from 'axios'
import { eventBus } from '../../main'
const baseURL = process.env.VUE_APP_API_URL
const token = `Bearer ${localStorage.getItem('token') || ''}`
const crossdomain = true

const client = axios.create({
  baseURL,
  headers: {
    Authorization: token,
    crossdomain: crossdomain
  }
})
client.interceptors.response.use((response) => {
  eventBus.$root.$emit('clearErrors')
  const { data } = response.data
  if (data !== undefined) {
    if (data.api_token) {
      axios.defaults.headers.common.Authorization = data.api_token
    }
  }
  return response
}, (error) => {
  eventBus.$root.$emit('setErrors', error.response)
  return Promise.reject(error)
})

client.interceptors.request.use((config) => {
  eventBus.$root.$emit('clearErrors')
  const token = `Bearer ${localStorage.getItem('token') || ''}`
  config.headers.Authorization = token
  return config
})

export default client
