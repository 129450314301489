<template>
  <div data-id="user-drop" :class="[{active: active_menu}, wrapperClasses]" @click="toggleMenu" v-click-outside="close">
    <div v-if="wrapUserInfo" :class="userInfoWrapperClasses">
      <div class="flx">
        <div class="user_image flx">
          <img src="~@/assets/images/user.png" alt="user_photo" v-if="!user.image">
          <img :src="'~@/assets/' + user.image" alt="user_photo" v-else>
        </div>
        <div class="user_info">
          <span class="user_name" v-if="!user.name">John Doe</span>
          <span class="user_name" v-else>{{ user.name | abbreviated }}</span>
          <span class="status blue" v-if="user.status">{{ user.status }}</span>
          <span class="status blue" v-else>Новичок</span>
        </div>
      </div>
      <div class="angle_down_wrap">
        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L4 4L7 1" stroke="#616C76" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <div class="user_image flx" v-if="!wrapUserInfo">
      <img src="~@/assets/images/user.png" alt="user_photo" v-if="!user.image">
      <img :src="'~@/assets/' + user.image" alt="user_photo" v-else>
    </div>
    <div class="user_info flx" v-if="!wrapUserInfo">
      <span class="user_name" v-if="!user.name">John Doe</span>
      <span class="user_name" v-else>{{ user.name | abbreviated }}</span>
      <span class="status blue" v-if="user.status">{{ user.status }}</span>
      <span class="status blue" v-else>Новичок</span>
    </div>
    <div class="angle_down_wrap" v-if="!wrapUserInfo">
      <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L4 4L7 1" stroke="#616C76" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div data-id="user-drop" :class="[{active: active_menu}, menuWrapperClasses]" v-if="wrapMenu">
      <div :class="menuClasses">
        <div class="drop_item">
          <router-link :to="{ name: 'Profile' }" class="flx">
            <img src="~@/assets/images/personal-ico.svg" alt="personal">
            <span>Кабинет</span>
          </router-link>
          <router-link :to="{ name: 'Orders' }" class="flx">
            <img src="~@/assets/images/order-ico.svg" alt="personal">
            <span>Заказы</span>
          </router-link>
          <router-link :to="{ name: 'Payments' }" class="flx">
            <img src="~@/assets/images/payment-ico.svg" alt="personal">
            <span>Выплаты</span>
          </router-link>
          <router-link :to="{ name: 'UploadYML' }" class="flx">
            <img src="~@/assets/images/payment-ico.svg" alt="personal">
            <span>Выгрузка товара</span>
          </router-link>
          <a target="_blank" href="https://t.me/brewdrop" class="flx">
            <img src="~@/assets/images/support-ico.svg" alt="personal">
            <span>Поддержка</span>
          </a>
          <router-link :to="{ name: 'PersonalData' }" class="flx">
            <img src="~@/assets/images/gear-ico.svg" alt="personal">
            <span>Личные данные</span>
          </router-link>
        </div>
        <div class="drop_item">
          <a href="#" class="log_out flx" @click="clearToken">
            <img src="~@/assets/images/exit-ico.svg" alt="personal">
            <span>Выйти</span>
          </a>
        </div>
      </div>
    </div>
    <div :class="menuClasses" v-else>
      <div class="drop_item">
        <router-link :to="{ name: 'Profile' }" class="flx">
          <img src="~@/assets/images/personal-ico.svg" alt="personal">
          <span>Кабинет</span>
        </router-link>
        <router-link :to="{ name: 'Orders' }" class="flx">
          <img src="~@/assets/images/order-ico.svg" alt="personal">
          <span>Заказы</span>
        </router-link>
        <router-link :to="{ name: 'Payments' }" class="flx">
          <img src="~@/assets/images/payment-ico.svg" alt="personal">
          <span>Выплаты</span>
        </router-link>
        <router-link :to="{ name: 'Help' }" class="flx">
          <img src="~@/assets/images/support-ico.svg" alt="personal">
          <span>Поддержка</span>
        </router-link>
        <router-link :to="{ name: 'PersonalData' }" class="flx">
          <img src="~@/assets/images/gear-ico.svg" alt="personal">
          <span>Личные данные</span>
        </router-link>
      </div>
      <div class="drop_item">
        <a href="#" class="log_out flx" @click="clearToken">
          <img src="~@/assets/images/exit-ico.svg" alt="personal">
          <span>Выйти</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { clickOutside } from '../../helpers/clickOutside'
import { strings } from '../../helpers/strings'
import { mapMutations } from 'vuex'

export default {
  name: 'UserMenu',
  mixins: [
    strings
  ],
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: null,
          status: 'Новичок',
          image: null
        }
      }
    },
    wrapperClasses: {
      type: Array,
      default: () => { return ['flx'] }
    },
    wrapUserInfo: {
      type: Boolean,
      default: false
    },
    wrapMenu: {
      type: Boolean,
      default: true
    },
    userInfoWrapperClasses: {
      type: Array,
      default: () => { }
    },
    menuWrapperClasses: {
      type: Array,
      default: () => { return ['menu_drop'] }
    },
    menuClasses: {
      type: Array,
      default: () => { return ['dropdown'] }
    }
  },
  directives: {
    clickOutside
  },
  data () {
    return {
      active_menu: false
    }
  },
  methods: {
    ...mapMutations('auth', ['clearToken']),
    toggleMenu () {
      this.active_menu = !this.active_menu
    },
    close () {
      if (this.active_menu) this.active_menu = false
    }
  }
}
</script>

<style scoped>

</style>
