<template>
  <header>
    <main-header></main-header>
    <sub-header></sub-header>
  </header>
</template>

<script>
import MainHeader from '../../components/Header/MainHeader'
import SubHeader from '../../components/Header/SubHeader'
export default {
  name: 'HeaderBlock',
  components: {
    MainHeader,
    SubHeader
  }
}
</script>

<style scoped>

</style>
