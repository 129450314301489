import Client from './../Clients/axiosClient'
const resource = '/api/users/auth'

export default {
  /**
   * get user
   * @returns {Promise<AxiosResponse<any>>}
   */
  get () {
    return Client.get(`${resource}`)
  }
}
