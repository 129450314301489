const state = () => ({
  errors: {}
})

// getters
const getters = {
  hasErrors: state => {
    return Object.keys(state.errors).length > 0
  },
  hasError: state => field => {
    return Object.prototype.hasOwnProperty.call(state.errors, [field])
  }
}

// actions
const actions = {
  setErrors ({ commit }, payload) {
    const { status, data } = payload
    switch (status) {
      case 401: {
        break
      }
      case 403: {
        this.$app.$notify(
          {
            type: 'error',
            group: 'notify',
            title: data.message
          }
        )
        break
      }
      case 500: {
        // TODO notify errors
        // data.message 'error', 'Произошла ошибка', 'Попробуйте позже или обратитесь в службу тех.поддержки'
        this.$app.$notify(
          {
            type: 'error',
            group: 'notify',
            title: 'Произошла ошибка',
            text: 'Попробуйте позже или обратитесь в службу тех.поддержки'
          }
        )
        break
      }
      case 404: {
        // TODO view 404  page
        break
      }
      case 422: {
        commit('setErrors', data.errors)
        this.$app.$notify(
          {
            type: 'warn',
            group: 'notify',
            title: data.message === 'The given data was invalid.' ? 'Неверные данные' : data.message,
            text: ''
          }
        )

        break
      }
      default: {
        console.log(data)
        break
      }
    }
  }
}

// mutations
const mutations = {
  setErrors (state, errors) {
    if (errors) state.errors = errors
  },
  clearErrors (state) {
    state.errors = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
