import axios from 'axios'
import repositoryFactory from '../../api/repositoryFactory'
const cart = repositoryFactory.get('cart')

// state
const state = () => ({
  carts: [],
  cart_counts: 0,
  total: 0,
  total_final: 0,
  total_check: 0,
  // ADD PRODUCTS
  product_list_filters: {
    category_id: null,
    vendor_code: null,
    name: null,
    season_id: null,
    price: null,
    price_from: null,
    price_to: null,
    per_page: 50,
    page: 1,
    order_by: 'id',
    order_direction: 'asc'
  },
  product_list: [],
  product_list_pagination: {
    last_page: 1
  },
  sender_is_fop: false
})

// getters
const getters = {
  getTotal: state => () => {
    let dropTotal = 0
    if (state.carts && state.carts.length > 0) {
      dropTotal = state.carts.reduce((total, product) => {
        if (product.discount) {
          return total + parseFloat(product.retail_price) * parseInt(product.qty) - product.discount.total * parseInt(product.qty)
        } else {
          return total + parseFloat(product.retail_price) * parseInt(product.qty)
        }
      }, 0)
    }
    return dropTotal
  },
  getProfit: (state, getters) => () => {
    return (state.total_final - getters.getTotal()).toFixed(2)
  }
}

// actions
const actions = {
  async getCarts ({ commit }) {
    if (localStorage.getItem('token')) {
      commit('setCarts', await cart.get())
    }
  },
  async addItemToCart ({ commit, dispatch }, payload) {
    await cart.create(payload)
      .then(() => {
        this.$app.$notify(
          {
            type: 'success',
            group: 'notify',
            title: 'Товар добавлен в корзину',
            text: ''
          }
        )
        dispatch('getCarts')
      })
  },
  async deleteCart ({ commit, dispatch }, payload) {
    await cart.delete(payload)
      .then(() => {
        this.$app.$notify(
          {
            type: 'success',
            group: 'notify',
            title: 'Корзина обновлена',
            text: ''
          }
        )
        dispatch('getCarts')
      })
  },
  clearCart ({ state, dispatch }) {
    const promises = state.carts.map(item => {
      return cart.delete(item.id)
    })
    Promise.all(promises)
      .then(() => {
        dispatch('getCarts')
        this.$app.$notify(
          {
            type: 'warning',
            group: 'notify',
            title: 'Товары удалены из корзины',
            text: ''
          }
        )
      })
  },
  getProductList ({ state, commit }) {
    axios.get('api/products/list/', {
      params: state.product_list_filters
    })
      .then(res => {
        commit('setProductList', res.data.data)
        commit('setProductListPagination', res.data.meta)
      })
      .catch(err => {
        console.log(err)
      })
  }
}

// mutations
const mutations = {
  setCarts (state, payload) {
    const { data } = payload
    if (data !== undefined) {
      state.carts = data.items
      state.cart_counts = data.count
      state.total = data.total
      state.sender_is_fop = data.sender_is_fop
    }
  },
  setCartItems (state, payload) {
    state.carts = payload
  },
  setTotalFinal (state, payload) {
    state.total_final = payload
  },
  setTotalCheck (state, payload) {
    state.total_check = payload
  },
  setProductList (state, products) {
    state.product_list = products.map(obj => ({ ...obj, in_order: state.carts.products && state.carts.products.length > 0 && state.carts.products.some(e => e.product_color_size_id === obj.product_color_size_id) }))
  },
  setProductListPagination (state, data) {
    state.product_list_pagination = data
  },
  setProductListFilters (state, filters) {
    state.product_list_filters = filters
  },
  clearProductListFilters (state) {
    state.product_list_filters = {
      category_id: null,
      vendor_code: null,
      name: null,
      season_id: null,
      price: null,
      price_from: null,
      price_to: null,
      per_page: 50,
      page: 1,
      order_by: 'id',
      order_direction: 'asc'
    }
  },
  setProductListPage (state, num) {
    state.product_list_filters.page = num
  },
  setProductListOrderby (state, payload) {
    state.product_list_filters.order_by = payload
  },
  setProductListOrderDirection (state, payload) {
    state.product_list_filters.order_direction = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
