import axios from 'axios'
const citiesUrl = 'api/cities/'
const branchesUrl = 'api/branches/'
const deliveriesUrl = 'api/deliveries/'
export default {
  namespaced: true,
  state: {
    search_branch: null,
    cities: [],
    branches: [],
    search_city: null,
    deliveries: [],
    search_address: null,
    addresses: []
  },
  mutations: {
    setSearchBranch (state, v) {
      state.search_branch = v
    },
    setCities (state, cities) {
      state.cities = cities
    },
    setBranches (state, branches) {
      state.branches = branches
    },
    setSearchCity (state, search) {
      state.serch_cities = search
    },
    setDeliveries (state, deliveries) {
      state.deliveries = deliveries
    },
    setAddresses (state, data) {
      state.addresses = data
    }
  },
  actions: {
    getCities ({ commit, state }, search) {
      // commit('setCities', [])
      axios.get(citiesUrl, {
        params: {
          name: search
        }
      })
        .then(res => {
          commit('setCities', res.data.data)
        })
        .catch(err => {
          console.log(err)
          commit('setCities', [])
        })
    },
    getCity ({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        axios.get(citiesUrl + id)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getBranches ({ commit, rootGetters, state }, city) {
      commit('setBranches', [])
      axios.get(branchesUrl, {
        params: {
          city_id: city.city_id,
          is_ua: city.is_ua,
          search: state.search_branch
        }
      })
        .then(res => {
          commit('setBranches', res.data.data)
        })
        .catch(err => {
          console.log(err)
          commit('setBranches', [])
        })
    },
    getAddresses ({ commit, rootGetters, state }, data) {
      commit('setAddresses', [])
      axios.get('/api/v1/addresses', {
        params: {
          city_ref: data.city_ref,
          street: data.street
        }
      })
        .then(res => {
          commit('setAddresses', res.data.data)
        })
        .catch(err => {
          console.log(err)
          commit('setAddresses', [])
        })
    },
    getBranch ({ commit, state }, id) {
      return new Promise((resolve, reject) => {
        axios.get(branchesUrl + id)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getBranchesByCityId ({ commit }, cityId) {
      return new Promise((resolve, reject) => {
        axios.get(branchesUrl, {
          params: {
            city_id: cityId
          }
        })
          .then(res => {
            resolve(res.data.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    getDeliveries ({ commit }) {
      axios.get(deliveriesUrl)
        .then(res => {
          commit('setDeliveries', res.data.data)
        })
        .catch(err => {
          console.log(err)
          commit('setDeliveries', [])
        })
    }
  }
}
