import axios from 'axios'

const defaultContent = {
  name: null,
  title: null,
  content: '',
  position: null,
  sort: 0
}

const baseUrl = '/api/guest/v1/contents/'

export default {
  namespaced: true,
  state: {
    position_list: {
      1: 'ИНФОРМАЦИЯ',
      2: 'СЛУЖБА ПОДДЕРЖКИ'
    },
    contents_info: [],
    contents_support: [],
    create_content: defaultContent,
    content: defaultContent,
    filters: {
      search: null,
      page: 1,
      per_page: localStorage.getItem('content-per-page') || 10
    },
    pagination: {
      last_page: 1
    }
  },
  mutations: {
    setContentsInfo (state, payload) {
      state.contents_info = payload
    },
    setContentsSupport (state, payload) {
      state.contents_support = payload
    },
    setContent (state, payload) {
      state.content = payload
    },
    clear (state) {
      state.content = defaultContent
    }
  },
  actions: {
    getContentsInfo ({ commit, state }) {
      axios.get(baseUrl,
        {
          params: {
            position: 1
          }
        }
      )
        .then(res => {
          commit('setContentsInfo', res.data.data)
        })
    },
    getContentSupport ({ commit, state }) {
      axios.get(baseUrl,
        {
          params: {
            position: 2
          }
        }
      )
        .then(res => {
          commit('setContentsSupport', res.data.data)
        })
    },
    getContentByURL ({ commit }, url) {
      axios.get(baseUrl + 'url/' + url).then(res => {
        commit('setContent', res.data.data)
      })
    }
  }
}
