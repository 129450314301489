import Client from './../Clients/axiosClient'
const resource = '/login'

export default {
  /**
   * Auth user
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  post (payload) {
    return Client.post(`${resource}`, payload)
  },
  get () {
    return Client.get(`${resource}`)
  },
  getPost (id) {
    return Client.get(`${resource}/${id}`)
  },
  create (payload) {
    return Client.post(`${resource}`, payload)
  },
  update (payload, id) {
    return Client.put(`${resource}/${id}`, payload)
  },
  delete (id) {
    return Client.delete(`${resource}/${id}`)
  }
  // MANY OTHER ENDPOINT RELATED STUFFS
}
