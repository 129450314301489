import axios from 'axios'

import repositoryFactory from '../../api/repositoryFactory'
const product = repositoryFactory.get('product')

// state
const state = () => ({
  color_id: null,
  products: [],
  favorites: [],
  stocks: [],
  meta: {
    current_page: 1,
    last_page: 1
  },
  meta_favorites: {
    current_page: 1,
    last_page: 1
  },
  meta_stocks: {
    current_page: 1,
    last_page: 1
  },
  product: null
})

// getters
const getters = {
  getNextShowMore: (state, getters, rootState) => () => {
    return Math.ceil(state.products.length / rootState.catalog.perPage) + state.meta.current_page
  },
  showBtnShowMore: (state, getters) => () => {
    return state.meta.last_page > getters.getNextShowMore()
  }
}

// actions
const actions = {
  async getProducts ({ commit, rootGetters }) {
    const products = await product.get(rootGetters['catalog/getFilters']())
    commit('setProducts', products)
    commit('setMeta', products)
    if (products.data.bread_crumbs) {
      commit('catalog/setCurrentCategoryName', products.data.bread_crumbs[0].name, { root: true })
    }
  },
  async addProducts ({ commit, rootGetters }) {
    const filters = rootGetters['catalog/getFilters']()
    filters.page = rootGetters['products/getNextShowMore']()
    const products = await product.get(filters)
    commit('addProductItems', products)
  },
  async getProduct ({ state, commit }, id) {
    commit('setProduct', await product.getItem(id, { color_id: state.color_id }))
  },
  async getFavorites ({ commit, rootGetters }) {
    axios.get('/api/guest/products/favorites', {
      params: rootGetters['catalog/getFilters']()
    })
      .then(res => {
        commit('setFavorites', res.data.data)
        commit('setMetaFavorites', res.data.meta)
      })
      .catch(err => {
        console.log(err)
      })
  },
  async getStocks ({ commit, rootGetters }) {
    axios.get('/api/stocks', {
      params: rootGetters['catalog/getFilters']()
    })
      .then(res => {
        commit('setStocks', res.data.data)
        commit('setMetaStocks', res.data.meta)
      })
      .catch(err => {
        console.log(err)
      })
  },
  downloadImages ({ commit }, id) {
    axios.post('api/products/images/' + id, {}, {
      responseType: 'blob'
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'images-' + id + '.zip')
        document.body.appendChild(link)
        link.click()
      })
      .finally(() => {
      })
  },
  async addToFavorites ({ commit, dispatch }, id) {
    axios.post('/api/guest/products/' + id + '/favorite')
      .then(res => {
        dispatch('getProduct', id)
      })
      .catch(err => {
        console.log(err)
      })
    this.$app.$notify(
      {
        type: 'success',
        group: 'notify',
        title: 'Товар добавлен в избранное',
        text: ''
      }
    )
  },
  async removeFavorite ({ commit, dispatch }, id) {
    axios.delete('/api/guest/products/' + id + '/favorite')
      .then(res => {
        dispatch('getProduct', id)
      })
      .catch(err => {
        console.log(err)
      })
    this.$app.$notify(
      {
        type: 'success',
        group: 'notify',
        title: 'Товар удален из избранного',
        text: ''
      }
    )
  }
}

// mutations
const mutations = {
  setColorId (state, payload) {
    state.color_id = payload
  },
  setProducts (state, payload) {
    const { data } = payload.data
    if (data !== undefined) {
      state.products = data
    }
  },
  setMeta (state, payload) {
    const { meta } = payload.data
    if (meta !== undefined) {
      state.meta = meta
    }
  },
  addProductItems (state, payload) {
    const { data } = payload.data
    if (data !== undefined) {
      state.products = [...state.products, ...data]
    }
  },
  clearProducts (state) {
    state.products = []
  },
  setProduct (state, payload) {
    const { data } = payload.data
    if (data !== undefined) {
      state.product = data
    }
  },
  clearProduct (state) {
    state.product = null
  },
  setFavorites (state, payload) {
    state.favorites = payload
  },
  setMetaFavorites (state, payload) {
    state.meta_favorites = payload
  },
  setStocks (state, payload) {
    state.stocks = payload
  },
  setMetaStocks (state, payload) {
    state.meta_stocks = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
