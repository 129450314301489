import axios from 'axios'

const baseUrl = 'api/deductions/'

export default {
  namespaced: true,
  state: {
    pre_create_data: [],
    invoices: [],
    show_modal: false
  },
  mutations: {
    setShowModal (state, value) {
      state.show_modal = value
    },
    setInvoices (state, data) {
      state.invoices = data
    },
    setPreCreateData (state, data) {
      state.pre_create_data = data
    }
  },
  actions: {
    preCreate ({ state, commit }) {
      axios.get(baseUrl + 'pre-create')
        .then(response => {
          commit('setInvoices', response.data.invoice_ids)
          commit('setPreCreateData', response.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    createDeduction ({ dispatch, state, commit }, data) {
      axios.post(baseUrl, data)
        .then(response => {
          commit('setShowModal', false)
          this.$app.$notify(
            {
              type: 'success',
              group: 'notify',
              title: 'Запрос создан'
            }
          )
        })
        .catch(err => {
          console.log(err)
          this.$app.$notify(
            {
              type: 'error',
              group: 'notify',
              title: 'Произошла ошибка'
            }
          )
        })
    }
  }
}
