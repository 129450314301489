import axios from 'axios'
import { eventBus } from '../main'

const RequestErrors = {
  connect () {
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response.status === 404) {
          // window.location.href = '/404-error'
        }

        if (error.response.status === 403) {
          eventBus.$root.$emit('setErrors', error.response)
        } else {
          eventBus.$root.$emit('setErrors', error.response)
        }
        return Promise.reject(error)
      })
  }
}

export default RequestErrors
