<template>
  <div id="app">
    <header-block></header-block>
    <router-view style="min-height: 550px"></router-view>
    <footer-block></footer-block>
    <notifications class="notify" position="top center" group="notify"/>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import FooterBlock from './views/blocks/FooterBlock'
import HeaderBlock from './views/blocks/HeaderBlock'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'App',
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: '',
      // all titles will be injected into this template
      titleTemplate: this?.$route?.query?.page ? `%s BrewDrop | Страница ${this.$route.query.page}` : '%s BrewDrop'
    }
  },
  provide: function () {
    return {
      isAuth: () => this.auth(),
      user_id: () => this.user ? this.user.id : null
    }
  },
  components: {
    HeaderBlock,
    FooterBlock
  },
  computed: {
    ...mapGetters('auth', ['auth']),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('errors', ['setErrors']),
    ...mapActions('auth', ['getUser']),
    ...mapMutations('errors', ['clearErrors']),
    ...mapActions('menu', ['getCategoriesMenu']),
    ...mapActions('cart', ['getCarts']),
    ...mapActions('config', ['getConfig'])
  },
  mounted () {
    console.log('MOUNTED')
    eventBus.$on('setErrors', (payload) => {
      this.setErrors(payload)
    })
    eventBus.$on('clearErrors', (payload) => {
      this.clearErrors()
    })
  },
  async created () {
    await this.getConfig()
    await this.getCategoriesMenu()
    await this.getUser()
    await this.getCarts()
  }
}
</script>

<style lang="scss">
@import "assets/scss/brew-style";
@import "assets/scss/tooltips";
</style>
