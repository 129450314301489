<template>
  <div class="container-404">
    <div class="flx-nowrap">
      <div style="display: flex">
        <img src="/images/logo-footer.svg" alt=""></div>
      <div>
        <div class="title-404">404</div>
        <div class="sub-title-404">Страница не найдена</div>
        <div @click="$router.go(-1)" class="sub-404">Вернуться назад</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  created () {
    // window.location.href = '/404.html'
  }
}
</script>

<style lang="scss">
  .container-404 {
    .flx-nowrap {
      //display: flex;
      justify-content: space-between;
    }
    width: 300px;
    margin: 50px auto;
    img {
      width: 200px;
      margin: 0 auto;
    }
    .title-404 {
      font-size: 110px;
      text-align: center;
      color: #626c76;
    }
    .sub-title-404 {
      font-size: 28px;
      text-align: center;
      color: #626c76;
      font-weight: 800;
    }
    .sub-404 {
      cursor: pointer;
      font-size: 28px;
      text-align: center;
      color: #f5a32a;
      font-weight: 800;
    }
  }
</style>
