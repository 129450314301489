<template>
  <div class="wrap_search_outer" :class="{ active: products.length > 0 }">
    <div class="wrap_search_inner flx">
      <label class="search">
        <button type="submit"></button>
        <input type="text" placeholder="Поиск товара по названию или артикулу" id="search" v-model="search_text">
        <span class="close" v-if="search_text.length > 0" @click="clear"></span>
      </label>
      <ul class="search_list_box" style="display: block" v-show="products.length > 0">
        <li class="item" v-for="product in products" :key="'product'+product.product_color_id">
<!--          <router-link :to="{ name: 'Product', params: { id: product.product_id }}">-->
<!--            <span> {{ product.name }} </span> <span v-if="product.color_name" style="color: darkorange"> ({{product.color_name}}) </span> <span v-if="product.retail_price"> {{product.retail_price}} грн</span>-->
<!--          </router-link>-->
          <a @click="link(product.product_id, product.product_color_id)">
            <span> {{ product.name }} </span> <span v-if="product.color_name" style="color: darkorange"> ({{product.color_name}}) </span> <span v-if="product.retail_price"> {{product.retail_price}} грн</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import router from '../../router'

export default {
  name: 'HeaderSearch',
  data () {
    return {
      timer: null
    }
  },
  computed: {
    ...mapState('search', ['search', 'products']),
    search_text: {
      get () {
        return this.search
      },
      set (val) {
        this.setSearch(val)
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.getProducts()
        }, 500)
      }
    }
  },
  methods: {
    ...mapActions('search', ['getProducts']),
    ...mapMutations('search', ['setSearch', 'clearProducts']),
    clear () {
      this.clearProducts()
      this.setSearch('')
    },
    link (productId, productColorId) {
      router.push({ name: 'Product', params: { id: productId }, query: { color_id: productColorId } })
      this.clear()
    }
  }
}
</script>

<style scoped>

</style>
