import repositoryFactory from '../../api/repositoryFactory'
import axios from 'axios'
const season = repositoryFactory.get('season')
const size = repositoryFactory.get('size')
const color = repositoryFactory.get('color')
const catalog = repositoryFactory.get('catalog')

const tempTreeCatalog = []
const tempSizes = []
const tempColors = []
const tempPrices = [
  {
    price_from: 0,
    price_to: 100
  },
  {
    price_from: 101,
    price_to: 200
  },
  {
    price_from: 201,
    price_to: 500
  },
  {
    price_from: 501,
    price_to: 1000
  }
]

// state
const state = () => ({
  current_category: null,
  current_category_name: null,
  childCategories: [],
  page: 1,
  perPage: parseInt(localStorage.getItem('catalog-per-page')) || 20,
  perPageItems: [20, 40, 60],
  itemsSortBy: [
    {
      name: 'По новинкам',
      value: 'created',
      direction: 'desc'
    },
    {
      name: 'От дешевых',
      value: 'price',
      direction: 'asc'
    },
    {
      name: 'От дорогих',
      value: 'price',
      direction: 'desc'
    },
    {
      name: 'В наличии',
      value: 'remains',
      direction: 'desc'
    }
  ],
  sortBy: localStorage.getItem('catalog-sort-by') || 'created',
  sortDirection: localStorage.getItem('catalog-sort-direction') || 'asc',
  seasons: [],
  season: null,
  categoriesTree: tempTreeCatalog,
  categories_ids: [],
  category_id: null,
  sizes: tempSizes,
  size: null,
  colors: tempColors,
  color: null,
  prices: tempPrices,
  price_from: null,
  price_to: null,
  children_categories: []
})

// getters
const getters = {
  getFilters: state => () => {
    return {
      page: state.page,
      per_page: state.perPage,
      order_by: state.sortBy,
      order_direction: state.sortDirection,
      season_id: state.season,
      categories_ids: state.categories_ids,
      size: state.size,
      color_id: state.color,
      price_from: state.price_from,
      price_to: state.price_to,
      category_id: state.category_id
    }
  },
  getBreadCrumbs: state => () => {
    const breadcrumbs = [
      {
        name: 'Магазин',
        pathName: 'HomePage',
        item_id: null
      }
    ]
    if (state.categories_ids.length === 1 && state.current_category) {
    //  TODO add breadcrumbs
    } else {
      breadcrumbs.push({
        name: 'Все категории',
        pathName: 'Catalog',
        item_id: null
      })
    }
    return breadcrumbs
  }
}

// actions
const actions = {
  async getSeasons ({ commit }) {
    commit('setSeasons', await season.get())
  },
  async getCategoriesTree ({ commit }) {
    axios.get('/api/guest/categories')
      .then(response => {
        commit('setCategoriesTree', response.data)
      })
      .catch(err => {
        console.log(err)
      })
    // TODO commit('setCategoriesTree', await catalog.get())
  },
  async getSizes ({ commit }) {
    commit('setSizes', await size.get())
  },
  async getColors ({ commit }) {
    commit('setColors', await color.get())
  },
  async getCurrentCategory ({ commit }, id) {
    commit('setCurrentCategory', await catalog.getItem(id))
  },
  getChildCategories (action) {
    axios.get('/api/categories', {
      params: {
        only_root: 0
      }
    }).then(response => {
      action.commit('setChildCategories', response.data.data)
    })
  }
}

// mutations
const mutations = {
  setCurrentCategoryName (state, newName) {
    state.current_category_name = newName
  },
  clearFilters (state) {
    console.log('clear filters')
    state.season = null
    state.categories_ids = []
    state.size = null
    state.color = null
    state.price_from = null
    state.price_to = null
    state.category_id = null
  },
  setPerPage (state, payload) {
    state.perPage = payload
    localStorage.setItem('catalog-per-page', payload)
  },
  setSortBy (state, payload) {
    state.sortBy = payload
    localStorage.setItem('catalog-sort-by', payload)
  },
  setPage (state, payload) {
    state.page = payload
  },
  setSortDirection (state, payload) {
    state.sortDirection = payload
    localStorage.setItem('catalog-sort-direction', payload)
  },
  setSeasons (state, payload) {
    const { data } = payload.data
    if (data !== undefined) {
      state.seasons = data
    }
  },
  setSeason (state, payload) {
    state.season = payload
  },
  setCategoriesTree (state, payload) {
    state.categoriesTree = payload.data
  },
  setCategoriesIds (state, payload) {
    state.categories_ids = payload
    // console.log('payload ' + payload)
    // state.categories_ids = payload.filter(function (id) {
    //   return state.categories_ids.indexOf(id) > -1
    // })
    // console.log('state.categories_ids', state.categories_ids)

    // for (let i = 0; i < payload.length; i++) {
    //   const parent = state.categoriesTree.find(x => x.id === payload[i])
    //   if (parent) {
    //     if (parent.children) {
    //       const childrenIds = parent.children.map(({ id }) => id)
    //       state.categories_ids = [...new Set([...state.categories_ids, ...childrenIds])]
    //     } else if (!state.categories_ids.includes(parent.id)) {
    //       state.categories_ids.push(parent.id)
    //     }
    //   } else if (!state.categories_ids.includes(payload[i])) {
    //     state.categories_ids.push(payload[i])
    //   }
    // }
  },
  setSizes (state, payload) {
    const { data } = payload.data
    if (data !== undefined) {
      state.sizes = data
    }
  },
  setSize (state, payload) {
    state.size = payload
  },
  setColors (state, payload) {
    const { data } = payload.data
    if (data !== undefined) {
      state.colors = data
    }
  },
  setColor (state, payload) {
    state.color = payload
  },
  setPriceFrom (state, payload) {
    state.price_from = payload
  },
  setPriceTo (state, payload) {
    state.price_to = payload
  },
  setCurrentCategory (state, payload) {
    const { data } = payload.data
    if (data !== undefined) {
      state.current_category = data
    }
  },
  clearCurrentCategory (state) {
    state.current_category = null
  },
  setChildCategories (state, data) {
    state.childCategories = data
  }
//   setCategoryId (state, payload) {
//     state.category_id = payload
// }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
