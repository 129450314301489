import repositoryFactory from '../../api/repositoryFactory'
import axios from 'axios'

const order = repositoryFactory.get('order')

// state
const state = () => ({
  counters: {
    all: 0,
    send: 0,
    returned: 0,
    completed: 0,
    lies_warehouse: 0
  },
  product_list_filters: {
    category_id: null,
    vendor_code: null,
    name: null,
    season_id: null,
    price: null,
    per_page: 50,
    page: 1,
    order_by: 'id',
    order_direction: 'asc'
  },
  order: {
    products: [],
    delivery_data: {
      dimensions: [{
        length: 0,
        width: 0,
        height: 0
      }]
    }
  },
  product_list: [],
  product_list_pagination: {
    last_page: 1
  },

  orders: [],
  selected_orders: [],
  current_order: null,
  meta: {
    current_page: 1,
    last_page: 1
  },
  perPageItems: [20, 40, 60],
  search: null,
  page: 1,
  per_page: +(localStorage.getItem('orders-per-page')) || 100,
  order_by: localStorage.getItem('orders-order-by') || 'id',
  order_direction: localStorage.getItem('orders-order-direction') || 'desc',
  order_id: null,
  sender_id: null,
  client_id: null,
  client_last_name: null,
  client_phone: null,
  status: null,
  delivery_status: null,
  category_id: null,
  change_from: null,
  change_to: null,
  change_id: 1,
  city_id: null,
  pay_type: null,
  show_filters: false,
  show_products_modal: false,
  is_upload_process: false
})

// getters
const getters = {
  getFilters: state => () => {
    return {
      market: 1,
      search: state.search,
      page: state.page,
      per_page: state.per_page,
      order_by: state.order_by,
      order_direction: state.order_direction,
      order_id: state.order_id,
      sender_id: state.sender_id,
      client_id: state.client_id,
      client_last_name: state.client_last_name,
      client_phone: state.client_phone,
      status: state.status,
      delivery_status: state.delivery_status,
      category_id: state.category_id,
      change_from: state.change_from,
      change_to: state.change_to,
      change_id: state.change_id,
      city_id: state.city_id,
      pay_type: state.pay_type
    }
  },
  count_order_filters: (state, getters) => () => Object.values(getters.getFilters()).filter(f => f).length - 6
}

// actions
const actions = {
  async getOrders ({ getters, commit }) {
    console.log(getters.getFilters())
    const orders = await order.get(getters.getFilters())
    commit('setOrders', orders)
    commit('setMeta', orders)
  },
  getOrder ({ commit }, id) {
    axios.get('api/orders/' + id).then(res => {
      commit('setOrder', res.data)
    })
  },
  toggleShowFilters ({ state, commit }) {
    commit('setShowFilters', !state.show_filters)
  },
  createOrder ({ commit, dispatch }, payload) {
    return new Promise(resolve => {
      order.create(payload)
        .then(response => {
          dispatch('cart/getCarts', null, { root: true })
          this.$app.$notify(
            {
              type: 'success',
              group: 'notify',
              title: 'Заказ создан'
            }
          )
          resolve(response.data)
        })
    })
  },
  updateOrder ({ dispatch, commit, state }, data) {
    axios.put('api/orders/' + state.order.id + '/seller', data).then(res => {
      commit('setOrder', res.data)
      this.$app.$notify(
        {
          type: 'info',
          group: 'notify',
          title: 'Данные заказа обновлены',
          text: ''
        }
      )
      commit('errors/clearErrors', null, { root: true })
    }).catch(e => {
      console.log(e.response)
      if (e.response) {
        dispatch('errors/setErrors', e.response, { root: true })
      }
    })
  },
  async getCounters ({ commit }) {
    commit('setCounters', await order.getCounters())
  },
  async uploadFile ({ commit, dispatch }, data) {
    commit('setIsUpload', true)
    axios.post('/api/orders/' + data.order_id + '/upload', data.data)
      .then(res => {
        // commit('setOrder', res.data)
        this.$app.$notify(
          {
            type: 'info',
            group: 'notify',
            title: 'Файл успешно загружен',
            text: ''
          }
        )
        commit('setIsUpload', false)
        commit('setOrderFileURL', res)
        commit('errors/clearErrors', null, { root: true })
      }).catch(e => {
        commit('setIsUpload', false)
      })
  }
}

// mutations
const mutations = {
  setIsUpload (state, v) {
    state.is_upload_process = v
  },
  setOrder (state, payload) {
    state.order = payload.data
  },
  setOrderFileURL (state, payload) {
    state.order.file = payload.data.url
  },
  setCounters (state, payload) {
    state.counters = payload.data
  },
  setOrders (state, payload) {
    const { data } = payload.data
    if (data !== undefined) {
      state.orders = data
    }
  },
  setMeta (state, payload) {
    const { meta } = payload.data
    if (meta !== undefined) {
      state.meta = meta
    }
  },
  setSearch (state, payload) {
    state.search = payload
  },
  setPage (state, payload) {
    state.page = payload
  },
  setPerPage (state, payload) {
    localStorage.setItem('orders-per-page', payload)
    state.per_page = payload
  },
  setOrderBy (state, payload) {
    state.order_by = payload
    localStorage.setItem('orders-order-by', payload)
  },
  setOrderDirection (state, payload) {
    state.order_direction = payload
    localStorage.setItem('orders-order-direction', payload)
  },
  setOrderId (state, payload) {
    state.order_id = payload
  },
  setSenderId (state, payload) {
    state.sender_id = payload
  },
  setClientId (state, payload) {
    state.client_id = payload
  },
  setClientLastName (state, payload) {
    state.client_last_name = payload
  },
  setClientPhone (state, payload) {
    state.client_phone = payload
  },
  setStatus (state, payload) {
    state.status = payload
  },
  setDeliveryStatus (state, payload) {
    state.delivery_status = payload
  },
  setCategoryId (state, payload) {
    state.category_id = payload
  },
  setChangeFrom (state, payload) {
    state.change_from = payload
  },
  setChangeTo (state, payload) {
    state.change_to = payload
  },
  setChangeId (state, payload) {
    state.change_id = payload
  },
  setCityId (state, payload) {
    state.city_id = payload
  },
  setPayType (state, payload) {
    state.pay_type = payload
  },
  clearFilters (state) {
    state.search = null
    state.page = 1
    state.per_page = +(localStorage.getItem('orders-per-page')) || 10
    state.order_by = localStorage.getItem('orders-order-by') || 'id'
    state.order_direction = localStorage.getItem('orders-order-direction') || 'desc'
    state.order_id = null
    state.sender_id = null
    state.client_id = null
    state.client_last_name = null
    state.client_phone = null
    state.status = null
    state.delivery_status = null
    state.category_id = null
    state.change_from = null
    state.change_to = null
    state.change_id = 1
    state.city_id = null
    state.pay_type = null
  },
  setShowFilters (state, payload) {
    state.show_filters = payload
  },
  setSelectedOrders (state, payload) {
    state.selected_orders = payload
  },
  setShowProductsModal (state, payload) {
    state.show_products_modal = payload
  },
  setCurrentOrder (state, payload) {
    state.current_order = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
