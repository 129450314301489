import Client from './../Clients/axiosClient'
const resource = '/api/guest/products/'
// const guestResource = '/api/guest/products/'

export default {
  get (payload) {
    // if (!localStorage.getItem('token')) {
    //   return Client.get(`${guestResource}`, { params: payload })
    // }
    return Client.get('/api/guest/v1/products/', { params: payload })
  },
  getItem (id, color) {
    // if (!localStorage.getItem('token')) {
    //   return Client.get(`${guestResource}${id}`)
    // }
    return Client.get(`${resource}${id}`, { params: color })
  }
}
