
import axios from 'axios'

// const catalog = repositoryFactory.get('catalog')
const baseUrl = '/api/guest/categories/'

// const tempSlides = [
//   {
//     image: 'images/hero1.png',
//     link: '/catalog?categories=1'
//   },
//   {
//     image: 'images/hero1.png',
//     link: '/catalog?categories=2'
//   },
//   {
//     image: 'images/hero1.png',
//     link: '/catalog?categories=3'
//   }
// ]

// state
const state = () => ({
  slides: [],
  categoryWallItems: [], //,
  trends: [],
  watched: []
})

// getters
const getters = {
}

// actions
const actions = {
  async getSlider ({ commit }) {
    axios.get('/api/guest/banners',
      {
        params: {
          type: 1
        }
      }
    ).then(res => {
      commit('setSlides', res.data)
    })
  },
  async getCategoryWall ({ commit }) {
    axios.get(baseUrl + 'slider', {
      params: {
        show_in_slider: 1
      }
    })
      .then(response => {
        commit('setCategoryWallItems', response.data)
      })
      .catch(err => {
        console.log(err)
      })
  },
  async getTrends ({ commit }) {
    axios.get('/api/guest/v1/products/in-trends')
      .then(response => {
        commit('setTrends', response.data)
      })
      .catch(err => {
        console.log(err)
      })
    // commit('setTrends', trends)
  },
  async getWatched ({ commit }) {
    axios.get('/api/guest/v1/products/viewed')
      .then(response => {
        commit('setWatched', response.data)
      })
      .catch(err => {
        console.log(err)
      })
  }
}

// mutations
const mutations = {
  setSlides (state, payload) {
    const { data } = payload
    if (data !== undefined) {
      state.slides = data
    }
  },
  setCategoryWallItems (state, payload) {
    state.categoryWallItems = payload.data
  },
  setTrends (state, payload) {
    state.trends = payload.data
  },
  setWatched (state, payload) {
    state.watched = payload.data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
