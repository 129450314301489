import Client from './../Clients/axiosClient'
const resource = '/api/orders'

export default {
  get (payload) {
    return Client.get(`${resource}`, { params: payload })
  },
  getCounters () {
    return Client.get(`${resource}/counters-for-user`)
  },
  getOrder (id) {
    return Client.get(`${resource}/${id}`)
  },
  create (payload) {
    return Client.post(`${resource}/cart`, payload)
  },
  update (payload, id) {
    return Client.put(`${resource}/${id}`, payload)
  },
  delete (id) {
    return Client.delete(`${resource}/${id}`)
  }
  // MANY OTHER ENDPOINT RELATED STUFFS
}
