<template>
  <div data-id="user-drop" :class="wrapperClasses">
     <router-link :to="{ name: 'Login' }" class="guest-user flx">
         <div class="guest-user--image flx">
           <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path fill-rule="evenodd" clip-rule="evenodd" d="M0.689941 11.831C0.689941 9.87076 2.27902 8.28169 4.23924 8.28169H9.76036C11.7206 8.28169 13.3097 9.87076 13.3097 11.831V13.2113C13.3097 13.6469 12.9565 14 12.5209 14C12.0853 14 11.7322 13.6469 11.7322 13.2113V11.831C11.7322 10.742 10.8494 9.85915 9.76036 9.85915H4.23924C3.15023 9.85915 2.26741 10.742 2.26741 11.831V13.2113C2.26741 13.6469 1.91428 14 1.47867 14C1.04307 14 0.689941 13.6469 0.689941 13.2113V11.831Z" fill="#3C434A"></path>
             <path fill-rule="evenodd" clip-rule="evenodd" d="M3.4505 3.5493C3.4505 1.58907 5.03958 0 6.9998 0C8.96002 0 10.5491 1.58907 10.5491 3.5493C10.5491 5.50952 8.96002 7.09859 6.9998 7.09859C5.03958 7.09859 3.4505 5.50952 3.4505 3.5493ZM6.9998 1.57746C5.91079 1.57746 5.02797 2.46028 5.02797 3.5493C5.02797 4.63831 5.91079 5.52113 6.9998 5.52113C8.08881 5.52113 8.97163 4.63831 8.97163 3.5493C8.97163 2.46028 8.08881 1.57746 6.9998 1.57746Z" fill="#3C434A"></path>
           </svg>
        </div>
       <div class="user_info">
         <span class="user_name">Вход</span>
       </div>
     </router-link>
  </div>
</template>

<script>
import { clickOutside } from '../../helpers/clickOutside'
export default {
  name: 'GuestLogin',
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: null,
          status: 'Новичок',
          image: null
        }
      }
    },
    wrapperClasses: {
      type: Array,
      default: () => { return ['flx'] }
    },
    wrapUserInfo: {
      type: Boolean,
      default: false
    },
    wrapMenu: {
      type: Boolean,
      default: true
    },
    userInfoWrapperClasses: {
      type: Array,
      default: () => { }
    },
    menuWrapperClasses: {
      type: Array,
      default: () => { return ['menu_drop'] }
    },
    menuClasses: {
      type: Array,
      default: () => { return ['dropdown'] }
    }
  },
  directives: {
    clickOutside
  },
  data () {
    return {
      active_menu: false
    }
  },
  methods: {
    toggleMenu () {
      this.active_menu = !this.active_menu
    },
    close () {
      if (this.active_menu) this.active_menu = false
    }
  }
}
</script>

<style scoped>

</style>
