import Client from './../Clients/axiosClient'
const resource = '/api/sizes/'

export default {
  /**
   * get seasons
   * @returns {Promise<AxiosResponse<any>>}
   */
  get () {
    return Client.get(`${resource}`)
  }
}
