import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import menu from './modules/menu'
import catalog from './modules/catalog'
import errors from './modules/errors'
import blocks from './modules/blocks'
import search from './modules/search'
import products from './modules/products'
import cart from './modules/cart'
import orders from './modules/orders'
import config from './modules/config'
import sender from './modules/sender'
import payments from './modules/payments'
import invoices from './modules/invoices'
import deductions from './modules/deductions'
import delivery from './modules/delivery'
import season from './modules/season'
import stock from './modules/stock'
import statistics from './modules/statistics'
import contents from './modules/contents'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    menu,
    catalog,
    errors,
    blocks,
    search,
    products,
    cart,
    orders,
    config,
    sender,
    payments,
    invoices,
    deductions,
    delivery,
    season,
    stock,
    statistics,
    contents
  }
})
