import repositoryFactory from '../../api/repositoryFactory'
const config = repositoryFactory.get('config')

// state
const state = () => ({
  config: null
})

// getters
const getters = {}

// actions
const actions = {
  async getConfig ({ commit }) {
    commit('setConfig', await config.get())
  }
}

// mutations
const mutations = {
  setConfig (state, payload) {
    const { data } = payload
    if (data !== undefined) {
      state.config = data
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
