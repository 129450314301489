export const strings = {
  filters: {
    truncate (text, stop, clamp) {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
    },
    abbreviated (str) {
      return (str === null || str === undefined || str === '' || typeof (str) !== 'string') ? '' : str.replace(/(\S+) (\S)\S* (\S)\S*/, '$1 $2. $3.')
    },
    asTTN (value) {
      if (!value) {
        return ''
      }
      const str = value.split('').reverse().join('')
      return [...str].map((d, i) => (i) % 4 === 0 ? ' ' + d : d).join('').trim().split('').reverse().join('')
    },
    firstLetter (str) {
      if (!str) {
        return ''
      }
      return str.charAt(0).toUpperCase() + '.'
    },
    removeCodeCountry: function (value) {
      if (!value) {
        return ''
      }
      let phoneNumber = value
      if (phoneNumber && phoneNumber.indexOf('+38') === 0) {
        phoneNumber = phoneNumber.substring(3)
      }
      return phoneNumber.replace(/[{()}]/g, '').replace(/-/g, ' ').trim()
    }
  }
}
