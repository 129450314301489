import repositoryFactory from '../../api/repositoryFactory'
const sender = repositoryFactory.get('sender')

// state
const state = () => ({
  senders: []
})

// getters
const getters = {}

// actions
const actions = {
  async getSenders ({ commit }) {
    commit('setSenders', await sender.get())
  }
}

// mutations
const mutations = {
  setSenders (state, payload) {
    const { data } = payload
    if (data !== undefined) {
      state.senders = data.data
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
