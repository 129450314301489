import Client from './../Clients/axiosClient'
const resource = '/api/categories/'
const guestResource = '/api/guest/categories/'

export default {
  /**
   * get categories
   * @param payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  get (payload) {
    if (!localStorage.getItem('token')) {
      return Client.get(`${guestResource}`, { params: payload })
    }
    return Client.get(`${resource}`, { params: payload })
  },
  getItem (id) {
    if (!localStorage.getItem('token')) {
      return Client.get(`${guestResource}${id}`)
    }
    return Client.get(`${resource}${id}`)
  }
}
