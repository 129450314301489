import authRepository from './Repositories/authRepository'
import branchRepository from './Repositories/branchRepository'
import cartRepository from './Repositories/cartRepository'
import categoryRepository from './Repositories/categoryRepository'
import cityRepository from './Repositories/cityRepository'
import colorRepository from './Repositories/colorRepository'
import configRepository from './Repositories/configRepository'
import orderRepository from './Repositories/orderRepository'
import seasonRepository from './Repositories/seasonRepository'
import senderRepository from './Repositories/senderRepository'
import sizeRepository from './Repositories/sizeRepository'
import sliderRepository from './Repositories/sliderRepository'
import userRepository from './Repositories/userRepository'
import productRepository from './Repositories/productRepository'

const repositories = {
  login: authRepository,
  user: userRepository,
  catalog: categoryRepository,
  slider: sliderRepository,
  product: productRepository,
  season: seasonRepository,
  size: sizeRepository,
  color: colorRepository,
  cart: cartRepository,
  order: orderRepository,
  config: configRepository,
  sender: senderRepository,
  city: cityRepository,
  branch: branchRepository
}
export default {
  get: name => repositories[name]
}
