import repositoryFactory from '../../api/repositoryFactory'
const product = repositoryFactory.get('product')

// state
const state = () => ({
  search: '',
  products: []
})

// getters
const getters = {

}

// actions
const actions = {
  async getProducts ({ commit, state }) {
    if (state.search.length > 0) {
      commit('setProducts', await product.get({ search: state.search, per_page: 10 }))
    } else {
      commit('clearProducts')
    }
  }
}

// mutations
const mutations = {
  setSearch (state, payload) {
    state.search = payload
  },
  setProducts (state, payload) {
    const { data } = payload.data
    if (data !== undefined) {
      state.products = data
    }
  },
  clearProducts (state) {
    state.products = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
