import axios from 'axios'
import repositoryFactory from '../../api/repositoryFactory'
const login = repositoryFactory.get('login')
// const userRepo = repositoryFactory.get('user')

// state
const state = () => ({
  user: null,
  token: localStorage.getItem('token') || null
})

// getters
const getters = {
  auth: state => () => { return state.token }
}

// actions
const actions = {
  authUser ({ commit, dispatch }, payload) {
    return new Promise(resolve => {
      login.post(payload)
        .then(data => {
          commit('setToken', data)
          commit('setUser', data)
          dispatch('getUser')
          resolve(data)
        })
    })
  },
  async getUser ({ dispatch, commit }) {
    await axios.get('/api/users/auth')
      .then(res => {
        commit('setUser', res)
        window.Echo.private('user.' + res.data.data.id)
          .listen('.billing-changed', (e) => {
            dispatch('getAuth')
            dispatch('invoices/getSocketOpenedInvoices', null, { root: true })
          })
      })
      .catch(err => {
        console.log(err)
      })
  },
  getAuth ({ dispatch, commit }) {
    axios.get('/api/users/auth')
      .then(res => {
        commit('setUser', res)
      })
      .catch(err => {
        console.log(err)
      })
  },
  updateUserData ({ state, dispatch, commit }, data) {
    axios.put('/api/users/' + state.user.id, data)
      .then(res => {
        commit('setUser', res)
        this.$app.$notify(
          {
            type: 'success',
            group: 'notify',
            title: 'Данные обновлены',
            text: ''
          }
        )
      })
      .catch(err => {
        console.log(err)
      })
  }
}

// mutations
const mutations = {
  setToken (state, payload) {
    const { data } = payload.data
    if (data !== undefined) {
      localStorage.setItem('token', data.api_token)
      state.token = data.api_token
      // TODO add token to axios
      location.reload()
    }
  },
  setUser (state, payload) {
    const { data } = payload.data
    if (data !== undefined) {
      state.user = data
      this.dispatch('invoices/getInvoiceUser', data.id, { root: true })
    }
  },
  clearToken (state) {
    state.token = ''
    state.user = null
    localStorage.setItem('token', '')
    location.reload()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
