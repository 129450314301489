import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import VueNotification from 'vue-notification'
import Paginate from 'vuejs-paginate'
import { pageClassPlugin } from './plugins/pageClassPlugin'
import VueMeta from 'vue-meta'
import VueClipboard from 'vue-clipboard2'
import BrewSelect from './helpers/components/BrewSelect'
import Echo from 'laravel-echo'
import VueClickOutsideElement from 'vue-click-outside-element'
import RequestErrors from './plugins/errorHelper'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)
Vue.use(VueClickOutsideElement)

window.io = require('socket.io-client')
console.log(process.env.VUE_APP_SOCKET_HOST)
console.log(localStorage.getItem('token') || 'test')

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token') || ''}`
axios.defaults.headers.crossdomain = true

export const eventBus = new Vue()
Vue.config.productionTip = false

Vue.use(VueNotification)
Vue.component('paginate', Paginate)
Vue.use(VueMeta)
Vue.use(pageClassPlugin)
Vue.use(VueClipboard)
Vue.component('brew-select', BrewSelect)

window.Echo = new Echo({
  broadcaster: 'socket.io',
  host: process.env.VUE_APP_SOCKET_HOST,
  auth: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token') || ''}`
    }
  }
})

RequestErrors.connect()

const app = new Vue({
  axios,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
store.$app = app
