<template>
  <div class="side_menu">
    <div class="menu_content">
      <user-menu
        v-if="isAuth&&user"
        :wrapper-classes="userWrapperClasses"
        :wrap-user-info="wrapUserInfo"
        :user-info-wrapper-classes="userInfoWrapperClasses"
        :menu-classes="userMenuClasses"
        :wrap-menu="userMenuWrap"
        :user="user"
      ></user-menu>
      <guest-login v-else
                   :wrapper-classes="userWrapperClasses"
      ></guest-login>
      <nav-menu class="sub_navigation" :items="sub_navs">
        <template v-slot="{ item }">
          <router-link :to="{ name: item.routeName }">{{item.title}}</router-link>
        </template>
      </nav-menu>
      <nav-menu class="main_navigation" :items="main_navs">
        <template v-slot="{ item }">
          <router-link :to="{ name: item.routeName }">{{item.title}}</router-link>
        </template>
      </nav-menu>
      <social-links></social-links>
    </div>
  </div>
</template>

<script>
import NavMenu from './NavMenu'
import { mapState } from 'vuex'
import SocialLinks from '../modules/SocialLinks/SocialLinks'
import UserMenu from './UserMenu'
import GuestLogin from './GuestLogin'
export default {
  name: 'MobileMenu',
  inject: ['isAuth'],
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: null,
          status: 'Новичок',
          image: null
        }
      }
    }
  },
  components: {
    NavMenu,
    SocialLinks,
    UserMenu,
    GuestLogin
  },
  data () {
    return {
      userWrapperClasses: ['user_wrapper_side'],
      wrapUserInfo: true,
      userInfoWrapperClasses: ['user_wrapper_side_inner', 'flx'],
      userMenuWrap: false,
      userMenuClasses: ['popover']
    }
  },
  computed: {
    ...mapState('menu', ['main_navs', 'sub_navs'])
  }
}
</script>

<style scoped>

</style>
