import axios from 'axios'
const baseUrl = '/api/guest/categories/'

const mainMenu = [
  {
    title: 'Доставка и оплата',
    routeName: 'DeliveryAndPayment'
  }
]
const subNavs = []
// state
const state = () => ({
  main_navs: mainMenu,
  sub_navs: subNavs,
  activeMenu: false
})

// getters
const getters = {

}

// actions
const actions = {
  toggleMenu ({ commit, state }) {
    commit('setActiveMenu', !state.activeMenu)
  },
  getCategoriesMenu ({ commit }) {
    axios.get(baseUrl + 'menu', {
      params: {
        show_in_menu: 1
      }
    })
      .then(response => {
        commit('setSubNavs', response.data.data)
      })
      .catch(err => {
        console.log(err)
      })
    // const { data } = await catalog.get({ only_root: true })
    // commit('setSubNavs', data.data)
  }
}

// mutations
const mutations = {
  setActiveMenu (state, payload) {
    state.activeMenu = payload
  },
  setSubNavs (state, payload) {
    state.sub_navs = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
