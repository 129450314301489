<template>
  <div class="sub_header_wrapper">
    <div class="sub_header flx">
      <div class="left flx">
        <nav-menu class="sub_navigation" :ul-class="'flx'" :items="sub_navs"></nav-menu>
      </div>
      <div class="right flx">
        <router-link
            v-if="user"
            style="margin-right: 35px"
            to='/checkout' class="btn item_social"
        >Создать заказ</router-link>
        <social-links></social-links>
      </div>
    </div>
    <mobile-menu :class="{ active: activeMenu}" :user="user"></mobile-menu>
  </div>
</template>

<script>
import NavMenu from './NavMenu'
import { mapState } from 'vuex'
import SocialLinks from '../modules/SocialLinks/SocialLinks'
import MobileMenu from './MobileMenu'
export default {
  name: 'SubHeader',
  components: {
    NavMenu,
    SocialLinks,
    MobileMenu
  },
  computed: {
    ...mapState('menu', ['main_navs', 'sub_navs', 'activeMenu']),
    ...mapState('auth', ['user'])
  }
}
</script>

<style scoped>

</style>
