import axios from 'axios'
const baseUrl = 'api/stocks/'

export default {
  namespaced: true,
  state: {
    stocks: [],
    page: 1,
    perPage: parseInt(localStorage.getItem('catalog-per-page')) || 20,
    perPageItems: [20, 40, 60],
    sortBy: localStorage.getItem('catalog-sort-by') || 'created',
    sortDirection: localStorage.getItem('catalog-sort-direction') || 'asc'
  },
  mutations: {
    setStocks (state, data) {
      state.stocks = data
    }
  },
  actions: {
    getStocks ({ commit }) {
      axios.get(baseUrl + 'home').then(response => {
        commit('setStocks', response.data.data)
      })
    }
  }
}
