import Client from './../Clients/axiosClient'
const resource = '/api/guest/banners'

export default {
  /**
   * get slides
   * @returns {Promise<AxiosResponse<any>>}
   */
  get () {
    return Client.get(`${resource}`)
  }
}
