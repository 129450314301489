
// state
import axios from 'axios'

const state = () => ({
  period: 'week',
  series: [{
    name: 'Заказов',
    data: [0]
  }],
  chartOptions: {
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: '',
      align: 'left'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      categories: [0]
    }
  },
  popular_goods: [],
  popular_categories: []
})

// mutations
const mutations = {
  setPopularCategories (state, payload) {
    state.popular_categories = payload
  },
  setStatistic (state, payload) {
    state.series = [{
      name: 'Заказов',
      data: payload.count
    }]
    state.chartOptions = {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      title: {
        text: '',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: payload.date
      }
    }
  },
  setPopularGoods (state, payload) {
    state.popular_goods = payload
  }
}

// actions
const actions = {
  getOrderGraph ({ state, commit }, period) {
    axios.get('api/users-statistic/graph?period=' + period).then(res => {
      commit('setStatistic', res.data)
    })
  },
  getOrderCustomGraph ({ state, commit }, date) {
    axios.get('api/users-statistic/graph', {
      params: {
        period: 'custom',
        from: date[0],
        to: date[1]
      }
    }).then(res => {
      commit('setStatistic', res.data)
    })
  },
  getPopularGoods ({ state, commit }) {
    axios.get('api/users-statistic/popular-goods').then(res => {
      commit('setPopularGoods', res.data)
    })
  },
  getPopularCategories ({ commit }) {
    axios.get('api/users-statistic/popular-categories').then(res => {
      commit('setPopularCategories', res.data)
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
