<template>
  <span id="burger" :class="{ active: active_menu }" @click="toggleMenu">
    <span></span>
    <span></span>
    <span></span>
  </span>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'BurgerButton',
  computed: {
    ...mapState('menu', ['activeMenu']),
    active_menu: {
      get () {
        return this.activeMenu
      },
      set (val) {
        this.setActiveMenu(val)
      }
    }
  },
  methods: {
    ...mapMutations('menu', ['setActiveMenu']),
    toggleMenu () {
      this.active_menu = !this.active_menu
    }
  }
}
</script>

<style scoped>

</style>
