import axios from 'axios'
import _ from 'lodash'
const baseUrl = 'api/invoices/'
const ordersUrl = 'api/invoices/processing/'
const billingUsers = '/api/billing/users/'
const paymentUrl = 'api/payments'
const calculatedUrl = 'api/invoices/calculated/'
const defaultInvoiceUsersFilters = {
  search: null,
  page: 1,
  per_page: parseInt(localStorage.getItem('invoice-users-per-page')) || 10,
  order_by: localStorage.getItem('invoice-users-order-by') || 'id',
  order_direction: localStorage.getItem('invoice-users-order-direction') || 'asc',
  dropshipper_id: null,
  phone: null,
  last_visited_from: null,
  last_visited_to: null,
  sum_from: null,
  sum_to: null,
  operation_id: null
}
export default {
  namespaced: true,
  state: {
    opened_invoices: [],
    in_process_orders: [],
    closed_invoices: [],
    opened_pagination: {
      last_page: 1
    },
    opened_filters: {
      search: null,
      page: 1,
      per_page: parseInt(localStorage.getItem('opened-invoices-per-page')) || 10,
      order_by: localStorage.getItem('opened-invoices-order-by') || 'id',
      order_direction: localStorage.getItem('opened-invoices-order-direction') || 'desc',
      user_id: null
    },
    closed_pagination: {
      last_page: 1
    },
    closed_filters: {
      search: null,
      page: 1,
      per_page: parseInt(localStorage.getItem('closed-invoices-per-page')) || 10,
      order_by: localStorage.getItem('closed-invoices-order-by') || 'id',
      order_direction: localStorage.getItem('closed-invoices-order-direction') || 'desc',
      user_id: null
    },
    in_process_pagination: {
      last_page: 1
    },
    in_process_filters: {
      search: null,
      page: 1,
      per_page: parseInt(localStorage.getItem('in-process-invoices-per-page')) || 10,
      order_by: localStorage.getItem('in-process-invoices-order-by') || 'id',
      order_direction: localStorage.getItem('in-process-invoices-order-direction') || 'desc',
      has_invoices: 0,
      dropshipper_id: null
    },
    invoice_users: [],
    invoice_users_pagination: {
      last_page: 1
    },
    invoice_users_filters: {
      search: null,
      page: 1,
      per_page: parseInt(localStorage.getItem('invoice-users-per-page')) || 10,
      order_by: localStorage.getItem('invoice-users-order-by') || 'id',
      order_direction: localStorage.getItem('invoice-users-order-direction') || 'desc',
      dropshipper_id: null,
      phone: null,
      last_visited_from: null,
      last_visited_to: null,
      sum_from: null,
      sum_to: null,
      operation_id: null
    },
    show_invoice_user_filters: false,
    invoice_user: null,
    selected_invoices: [],
    show_calculate_modal: false
  },
  getters: {
    count_user_filters: state => Object.values(state.invoice_users_filters).filter(f => f).length - 2,
    getInvoicesCalcField: state => (field) => {
      if (!state.invoice_user) {
        return 0
      }
      if (state.selected_invoices.length === 0) {
        return state.invoice_user.billing_info[field]
      } else {
        let t = 0
        for (let i = 0; i < state.selected_invoices.length; i++) {
          const invoice = _.find(state.opened_invoices, { id: state.selected_invoices[i] })
          if (invoice) {
            if (invoice.amount > 0 && field === 'debit') {
              t += invoice.amount
            } else if (invoice.amount < 0 && field === 'pay') {
              t += invoice.amount
            }
          }
        }
        return t
      }
    },
    getInvoicesCalcTotal: state => () => {
      if (!state.invoice_user) {
        return 0
      }
      if (state.selected_invoices.length === 0) {
        return state.invoice_user.billing_info.total
      } else {
        let t = 0
        for (let i = 0; i < state.selected_invoices.length; i++) {
          const invoice = _.find(state.opened_invoices, { id: state.selected_invoices[i] })
          if (invoice) {
            t += invoice.amount
          }
        }
        return t
      }
    }
  },
  mutations: {
    setInvoiceUsers (state, users) {
      state.invoice_users = users
    },
    setInvoiceUser (state, user) {
      state.invoice_user = user
    },
    setInvoiceUsersPagination (state, data) {
      state.invoice_users_pagination = data
    },
    setInvoiceUsersPage (state, num) {
      state.invoice_users_filters.page = num
    },
    setInvoiceUsersPerPage (state, num) {
      state.invoice_users_filters.per_page = num
      localStorage.setItem('invoice-users-per-page', `${num}`)
    },
    setInvoiceUsersOrderBy (state, payload) {
      state.invoice_users_filters.order_by = payload
      localStorage.setItem('invoice-users-order-by', payload)
    },
    setInvoiceUsersOrderDirection (state, payload) {
      state.invoice_users_filters.order_direction = payload
      localStorage.setItem('invoice-users-order-direction', payload)
    },
    setInvoiceUsersSearch (state, searchString) {
      state.invoice_users_filters.search = searchString
    },
    setInvoiceUserFilters (state, payload) {
      state.show_invoice_user_filters = payload
    },
    clearInvoiceUsersFilters (state) {
      state.invoice_users_filters = defaultInvoiceUsersFilters
    },
    setOpenedInvoices (state, invoices) {
      state.opened_invoices = invoices
    },
    setInProcessOrders (state, orders) {
      state.in_process_orders = orders
    },
    setClosedInvoices (state, invoices) {
      state.closed_invoices = invoices
    },
    setOpenedPagination (state, data) {
      state.opened_pagination = data
    },
    setInProcessPagination (state, data) {
      state.In_process_pagination = data
    },
    setOpenedPage (state, num) {
      state.opened_filters.page = num
    },
    setOpenedPerPage (state, num) {
      state.opened_filters.per_page = num
      localStorage.setItem('opened-invoices-per-page', `${num}`)
    },
    setOpenedInvoiceOrderBy (state, payload) {
      state.opened_filters.order_by = payload
      localStorage.setItem('opened-invoices-order-by', payload)
    },
    setOpenedInvoiceOrderDirection (state, payload) {
      state.opened_filters.order_direction = payload
      localStorage.setItem('opened-invoices-order-direction', payload)
    },
    setOpenedSearch (state, searchString) {
      state.opened_filters.search = searchString
    },
    setInProcessPage (state, num) {
      state.in_process_filters.page = num
    },
    setInProcessPerPage (state, num) {
      state.in_process_filters.per_page = num
      localStorage.setItem('in_process-invoices-per-page', `${num}`)
    },
    setInProcessInvoiceOrderBy (state, payload) {
      state.in_process_filters.order_by = payload
      localStorage.setItem('in_process-invoices-order-by', payload)
    },
    setInProcessInvoiceOrderDirection (state, payload) {
      state.in_process_filters.order_direction = payload
      localStorage.setItem('in_process-invoices-order-direction', payload)
    },
    setInProcessSearch (state, searchString) {
      state.in_process_filters.search = searchString
    },
    setClosedPagination (state, data) {
      state.closed_pagination = data
    },
    setClosedPage (state, num) {
      state.closed_filters.page = num
    },
    setClosedPerPage (state, num) {
      state.closed_filters.per_page = num
      localStorage.setItem('closed-invoices-per-page', `${num}`)
    },
    setClosedInvoiceOrderBy (state, payload) {
      state.closed_filters.order_by = payload
      localStorage.setItem('closed-invoices-order-by', payload)
    },
    setClosedInvoiceOrderDirection (state, payload) {
      state.closed_filters.order_direction = payload
      localStorage.setItem('closed-invoices-order-direction', payload)
    },
    setClosedSearch (state, searchString) {
      state.closed_filters.search = searchString
    },
    setUserId (state, userId) {
      // state.invoice_users_filters.user_id = userId
      state.opened_filters.user_id = userId
      state.closed_filters.user_id = userId
      state.in_process_filters.dropshipper_id = userId
    },
    setSelectedInvoices (state, ids) {
      state.selected_invoices = ids
    },
    setShowCalculateModal (state, payload) {
      state.show_calculate_modal = payload
    }
  },
  actions: {
    getInvoiceUsers ({ state, commit }) {
      axios.get(billingUsers, {
        params: {
          per_page: state.invoice_users_filters.per_page,
          roles: state.invoice_users_filters.roles,
          search: state.invoice_users_filters.search,
          page: state.invoice_users_filters.page,
          user_id: state.invoice_users_filters.user_id || null,
          phone: state.invoice_users_filters.phone,
          last_visited_from: state.invoice_users_filters.last_visited_from || null,
          last_visited_to: state.invoice_users_filters.last_visited_to || null,
          sum_from: state.invoice_users_filters.sum_from || null,
          sum_to: state.invoice_users_filters.sum_to || null,
          operation_id: state.invoice_users_filters.operation_id || null,
          order_by: state.invoice_users_filters.order_by || null,
          order_direction: state.invoice_users_filters.order_direction || null
        }
      }).then(response => {
        commit('setInvoiceUsers', response.data.data)
        commit('setInvoiceUsersPagination', response.data.meta)
      }).catch(err => {
        console.log(err)
      })
    },
    getInvoiceUser ({ state, commit }, id) {
      axios.get(billingUsers + id)
        .then(res => {
          commit('setInvoiceUser', res.data.data)
        })
        .catch(err => {
          console.log(err)
          commit('setInvoiceUser', null)
        })
    },
    getSocketOpenedInvoices ({ dispatch, state, commit }) {
      if (state.opened_pagination.current_page === 1) {
        dispatch('getOpenedInvoices')
      }
    },
    getOpenedInvoices ({ state, commit }) {
      axios.get(baseUrl, { params: state.opened_filters })
        .then(res => {
          commit('setOpenedInvoices', res.data.data)
          commit('setOpenedPagination', res.data.meta)
        })
        .catch(err => {
          console.log(err)
          commit('setOpenedInvoices', [])
        })
    },
    getInProcessOrders ({ state, commit }) {
      axios.get(ordersUrl, { params: state.in_process_filters })
        .then(res => {
          commit('setInProcessOrders', res.data.data)
          commit('setInProcessPagination', res.data.meta)
        })
        .catch(err => {
          console.log(err)
          commit('setInProcessOrders', [])
        })
    },
    getClosedInvoices ({ state, commit }) {
      axios.get(calculatedUrl, { params: state.closed_filters })
        .then(res => {
          commit('setClosedInvoices', res.data.data)
          commit('setClosedPagination', res.data.meta)
        })
        .catch(err => {
          console.log(err)
          commit('setClosedInvoices', [])
        })
    },
    toggleInvoiceUserFilters ({ state, commit }) {
      commit('setInvoiceUserFilters', !state.show_invoice_user_filters)
    },
    createPayment ({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(paymentUrl, data)
          .then(res => {
            this.dispatch('deduction/getDeductionCount', null, { root: true })
            resolve({ success: true })
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      })
    }
  }
}
